export interface ActionType {
  type: string
}

export interface Message {
  id: string,
  name: string,
  email: string,
  message: string,
  possibleSpam: boolean,
  created: string
}

export interface MessagesState {
  messages: Message[],
  loading: boolean,
  message: Message | null
}

export enum FlashType {
  Success = 'success',
  Error = 'error',
  None = 'none'
}

export interface AuthState {
  token: string
  flash: string
  mType: FlashType
  loading: boolean
}
