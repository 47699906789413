import React from 'react'
import { Link } from 'react-router-dom'

import formatDate from '../UI/Helpers/formatDate'
import DeleteIcon from '../UI/Graphics/Delete'
import css from '../../styles/messagelink.module.css'
import cssLocal from '../../styles/deletebutton.module.css'
import { Message } from '../../interfaces'
interface MessageLinkProps {
  message: Message,
  onDeleteMessage: (id: string) => void
}

const MessageLink = (props: MessageLinkProps) => {

  const message = props.message
  const path = `/messages/${ message.id }`

  return (
    <React.Fragment>
    <li className={ css.MessageLink } >
        <Link to={ path } >
          <span className={css.Date}>
            {formatDate(props.message.created)}
          </span>
          <span className={css.FirstChild}>{ props.message.name }</span>
          <span className={props.message.possibleSpam ? css.possibleSpam : css.NoSpam }>{ props.message.email }</span>
        </Link>
          <span className={css.ThirdChild}>{ props.message.message.length > 100 ? props.message.message.slice(0, 98)+"..." : props.message.message }</span>
          <span className={[css.FourthChild, cssLocal.DeleteButton].join(' ')} onClick={()=> props.onDeleteMessage(props.message.id)}>
            <DeleteIcon />
          </span>
        </li>
    </React.Fragment>

  )
}

export default MessageLink
