import { Message } from '../../interfaces'

export const START_FETCH_MESSAGES = 'START_FETCH_MESSAGES'
export const SET_MESSAGES = 'SET_MESSAGES'
export const SET_MESSAGE = 'SET_MESSAGE'
export const FETCH_MESSAGE = 'FETCH_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

export const MOVE_MESSAGE = 'MOVE_MESSAGE'

export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FETCH = 'AUTH_FETCH'
export const AUTH_FAIL = 'AUTH_FAIL'

export const LOGOUT = 'LOGOUT'

interface StartFetchMessagesAction {
  type: typeof START_FETCH_MESSAGES
}

interface SetMessagesAction {
  type: typeof SET_MESSAGES,
  messages: Message[]
}

interface FetchMessageAction {
  type: typeof FETCH_MESSAGE,
  id: string
}

interface SetMessageAction {
  type: typeof SET_MESSAGE,
  message: Message
}

interface ClearMessagesAction {
  type: typeof CLEAR_MESSAGES
}

interface DeleteMessageAction {
  type: typeof DELETE_MESSAGE
}

interface AuthStartAction {
  type: typeof AUTH_START
}

interface AuthSuccessAction {
  type: typeof AUTH_SUCCESS,
  token: string
}

interface AuthFailAction {
  type: typeof AUTH_FAIL,
  message: string,
  statusCode: number
}

interface AuthFetchAction {
  type: typeof AUTH_FETCH,
  token: string
}

interface Logout {
  type: typeof LOGOUT
}

export type AuthActionTypes = AuthStartAction  | AuthSuccessAction | AuthFetchAction | AuthFailAction | Logout
export type MessagesActionTypes = StartFetchMessagesAction | SetMessagesAction | ClearMessagesAction | FetchMessageAction | SetMessageAction | DeleteMessageAction
