import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { useHistory} from 'react-router-dom'

import * as actions from '../../store/actions'
import css from '../../styles/form.module.css'
import { FlashType } from '../../interfaces'
import Spinner from '../UI/Spinner/Spinner'

interface FieldProps {
  username: string,
  password: string
}

// any is fallback to use handleSubmit
const Form = (props: any) => {

  const history = useHistory()
  const { handleSubmit } = props
  const onSubmitHandler = (fieldProps: FieldProps) => {
    props.onLoading()
    props.onLoginAction(fieldProps, () => {
        history.push("/messages")
    })
  }
  
  let flashMessage: React.ReactElement
  switch (props.mType) {
    case 'error':
      flashMessage = (
        <span className={css.Error}>
        { props.flashMessage }
      </span>
      )
      break
    case 'success':
      flashMessage = (
        <span className={css.Success}>
        { props.flashMessage }
      </span>
      )
      break
    default:
      flashMessage = <span className={css.None}></span>
      break
  }

  let loading: React.ReactElement
  loading = props.loading ? <Spinner /> : <span></span>

  return (
    <section className={ css.FormContent }>
      <form className={ css.Form } 
            onSubmit={handleSubmit(onSubmitHandler)}>
        <span className={ css.Flash }>
          {flashMessage}
        </span>
        <fieldset>
          <label>Username
            <Field 
            name="username"
            type="text"
            component="input"
            autoComplete="none"         
            />
          </label>
          <label>Password
            <Field 
            name="password"
            type="password"
            component="input"            
            autoComplete="none"         
            />
          </label>
        </fieldset>
        { loading }
        <button>Login</button>
      </form>
      </section>
  )
}

interface State {
  auth: {
    flash: string,
    mType: FlashType,
    token: string,
    loading: boolean
  }
}

const mapStateToProps = (state: State) => {
  return {
    flashMessage: state.auth.flash,
    mType: state.auth.mType,
    token: state.auth.token,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLoginAction: (data:FieldProps, callback: Function) => dispatch(actions.authorize(data, callback)),
    onLoading: () => dispatch(actions.startAuth())
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector((reduxForm({ form: 'login'})(Form)))
