import React from 'react'
import  spinningDisc from '../../../assets/icons/spinningDisc.png'
import css from '../../../styles/spinner.module.css'


const Spinner = () => (
  <div className={css.Spinner}>
    <img src={spinningDisc} alt="Loading..."/>
  </div>
)

export default Spinner
