import React from 'react'

import css from '../../styles/footer.module.css'

const Footer = () => {

  return (
    <footer className={css.Footer}>
      <a href="https://nataloo.com" target="_blank"  rel="noopener noreferrer">
        <span>©Nataloo</span>
      </a>
    </footer>
  )
}

export default Footer
