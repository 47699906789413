import axios from 'axios'

// const url = process.env.NODE_ENV === 'development' 
//       ? 'http://localhost:4769' 
//       : 'https://theevillemon.com'
const url = 'https://theevillemon.com'

const axiosInstance = axios.create({
  baseURL: url
})

export default axiosInstance
