import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import messages from './messages'

const reducers = combineReducers({
  auth,
  messages,
  form: formReducer
})

export default reducers
