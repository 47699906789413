import { AuthState, FlashType } from '../../interfaces'
import * as actionTypes from '../actions/actionTypes'
import {updateObject} from './utils'

const initialState: AuthState = {
  token:'',
  flash:'',
  mType: FlashType.None,
  loading: false
}

const setErrorMessage = (status: number): string => {
  let msg: string
  switch (status) {
    case 400:
      msg = "You must supply a username AND password"
      break;
    case 404:
      msg = "Sorry, that wasn't right"
      break;
    case 401:
      msg = "Sorry, that wasn't right"
      break;  
    default:
      msg = "Oops, something went wrong"
  }
  
  return msg
}

const reducer = (state = initialState, action: actionTypes.AuthActionTypes) => {
  let message: string
    switch (action.type) {
      case actionTypes.AUTH_START:
        return updateObject(state, {flash: '', mType: FlashType.None, loading: true})
      case actionTypes.AUTH_SUCCESS:
        return updateObject(state, {token: action.token, flash: '', mType: FlashType.None, loading: false})
      case actionTypes.AUTH_FAIL:
        message = setErrorMessage(action.statusCode)
        return updateObject(state, {flash: message, mType: FlashType.Error, loading: false})        
      case actionTypes.LOGOUT:
        message = "You've been logged out"
        return updateObject(state, {token: '', flash: message, mType: FlashType.Success, loading: false})        
      default: return state
    }
}

export default reducer
