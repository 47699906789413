import {MessagesState} from '../../interfaces'
import * as actionTypes from '../actions/actionTypes'

import {updateObject} from './utils'

const initialState: MessagesState = {
  messages: [],
  loading: false,
  message: null
}


const reducer =
    (state = initialState, action: actionTypes.MessagesActionTypes) => {
      switch (action.type) {
        case actionTypes.START_FETCH_MESSAGES:
          return updateObject(state, {loading: true})
        case actionTypes.SET_MESSAGES:
          return updateObject(state, {messages: action.messages, loading: false})
        case actionTypes.CLEAR_MESSAGES:
          return updateObject(state, {messages: [], loading: false, message: null})
        case actionTypes.FETCH_MESSAGE:
          const msg = state.messages.find(m => m.id === action.id)
          return updateObject(state, {message: msg})
        case actionTypes.SET_MESSAGE:
          return updateObject(state, {message: action.message, loading: false})
        case actionTypes.DELETE_MESSAGE:
          return updateObject(state, {message: null})
        default:
          return state
      }
    }

export default reducer
