import React from 'react'
import {connect, ConnectedProps} from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import css from '../../styles/nav.module.css'

import {deleteToken} from '../../store/actions'

type PropsFromRedux = ConnectedProps<typeof connector>

type NavProps = PropsFromRedux & {}

const Nav = (props: NavProps) => {

  const history = useHistory()
  
  const onLogoutHandler = () => {
    props.onLogout(()=>{
      history.push("/")
    })
  }

  return (
    <div className={css.Nav}>
      <nav>
        <ul>
          <li><Link to="/messages">Messages</Link></li>
          <li onClick={onLogoutHandler}>Logout</li>
        </ul>
      </nav>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: (callback: Function) => dispatch(deleteToken(callback))
  }
}

const connector = connect(null, mapDispatchToProps)

export default connector(Nav)
