import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { fetchMessages, startFetchMessages, deleteMessage} from '../../store/actions/index'

import Spinner from '../UI/Spinner/Spinner'
import WeepingFace from '../UI/Weeping/Weeping'
import { Message } from '../../interfaces'
import MessageLink from '../MessageLink/MessageLink'
import css from '../../styles/mainview.module.css'

type PropsFromRedux = ConnectedProps<typeof connector>

type MainViewProps = PropsFromRedux & {}

const MainView: React.FC<MainViewProps> = (props: MainViewProps): JSX.Element => {

  const { onFetchMessages, onStartFetchMessages, token } = props

  useEffect(() => {
    onStartFetchMessages()
    onFetchMessages(token)
  },[onFetchMessages, onStartFetchMessages, token])

  const reloadMessages = () => {
    onStartFetchMessages()
    onFetchMessages(token)
  }

  const deleteMessageHandler = (id: string) => {
    props.onDeleteMessageHandler(id, props.token, () => {
      onStartFetchMessages()
      onFetchMessages(token)
    })
  }

  let msgs
  msgs = props.messages ?  props.messages.map(m => (
    <MessageLink onDeleteMessage={deleteMessageHandler} message={ m } key={m.id}/>
  )) : <p className={css.NoMessages}><span>No messages.</span> <WeepingFace /></p>
  let loading: React.ReactElement
  loading = props.loading ? <Spinner /> : <span></span>

  return (
    <div className={ css.MainView }>
      <section className={ css.Sidedrawer }>
        <h1>Main Viewer</h1>
        <button onClick={reloadMessages}>Fetch</button>
      </section>
      <section className={ css.Messages }>
        <ul >
          { loading }
          { msgs }
        </ul>
      </section>
    </div>)
}

interface State {
  auth: {
    token: string
  },
  messages: {
    messages: Message[],
    loading: boolean
  }
}

const mapStateToProps = (state: State) => ({
    token: state.auth.token,
    messages: state.messages.messages,
    loading: state.messages.loading
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    onStartFetchMessages: () => dispatch(startFetchMessages()),
    onFetchMessages: (token: string) => dispatch(fetchMessages(token)),
    onDeleteMessageHandler: (id: string, token: string, callback: Function) => dispatch(deleteMessage(id, token, callback))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(MainView)
