import React, { useEffect } from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {useHistory} from 'react-router-dom'

import Spinner from '../UI/Spinner/Spinner'
import formatDate from '../UI/Helpers/formatDate'
import DeleteIcon from '../UI/Graphics/Delete'
import { Message } from '../../interfaces'
import { fetchMessage, startFetchMessages, deleteMessage } from '../../store/actions'

import css from '../../styles/singleview.module.css'
import cssLocal from '../../styles/deletebutton.module.css'

type SingleViewProps = ConnectedProps<typeof connector>

const SingleView = (props: SingleViewProps) => {
  const id = document.location.pathname.split('/')[ 2 ]
  const { token, onFetchMessage, onStartFetchingMessage } = props
  const history = useHistory()

  useEffect(() => {
    onStartFetchingMessage()
    onFetchMessage(id, token)
  }, [id, onFetchMessage, onStartFetchingMessage, token])

  const deleteHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    console.log('click')
    props.onDeleteMessage(id, props.token, () => {
      history.push("/messages")
    })
  }

  let messageMarkup = props.message === null ? null : (
      <div className={css.MessageContent}>
        <span className={css.possibleSpam}>
          {props.message.possibleSpam ? "Possible SPAM" : null}
        </span>
        <span className={css.Created}>
          {formatDate(props.message.created)}
        </span>
        <span>
          {props.message.name}
        </span>
        <span>
          <a href={"mailto:" + props.message.email}>
            {props.message.email}
          </a>
        </span>
        <span>
          {props.message.message.split("\n\n").map((p, i) => (
            <p key={i}>
              {p}
            </p>
          ))}
        </span>
        <span onClick={deleteHandler}  className={[css.delete, cssLocal.DeleteButton].join(' ')}>
          <DeleteIcon />
        </span>
      </div>  
    ) 

  let loading: React.ReactElement
  loading = props.loading ? <Spinner /> : <span></span>

  return (
    <div className={ css.SingleView }>
      <h1>Message</h1>
      { loading }
      {messageMarkup}
    </div>
  )
}

interface State {
  auth: {
    token: string
  },
  messages: {
    messages: Message[],
    loading: boolean,
    message: Message 
  }
}


const mapStateToProps = (state: State) => {
  return {
    token: state.auth.token,
    messages: state.messages.messages,
    loading: state.messages.loading,
    message: state.messages.message
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onStartFetchingMessage: () => dispatch(startFetchMessages()),
    onFetchMessage: (id: string, token: string) => dispatch(fetchMessage(id, token)),
    onDeleteMessage: (id: string, token: string, callback: Function) => dispatch(deleteMessage(id, token, callback))
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(SingleView)
