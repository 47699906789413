import axiosInstance from '../axios-instance'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { Message, MessagesState } from '../../interfaces'
import * as actionTypes from './actionTypes'

export const startFetchMessages = () => {
  return {
    type: actionTypes.START_FETCH_MESSAGES
  }
}

export const fetchMessages = (token: string): ThunkAction<void, MessagesState, unknown, Action<string>> => {
  // ASYNC actions
  return dispatch => {
    const bearerToken = "Bearer " + token
    axiosInstance.defaults.headers.common[ "Authorization" ] = bearerToken

    const pathName = '/messages'
    axiosInstance.get(pathName)
      .then(res => {
        dispatch(setMessages(res.data))
      })
      .catch(err => console.log(err))
  }
}

export const fetchMessage = (id: string, token: string): ThunkAction<void, MessagesState, unknown, Action<string>> => {
  return dispatch => {
    const bearerToken = "Bearer " + token
    axiosInstance.defaults.headers.common[ "Authorization" ] = bearerToken

    const pathName = `/messages/${id}`
    axiosInstance.get(pathName)
      .then(res => {
        dispatch(setMessage(res.data))
      })
      .catch(err => console.log(err))
  }
}

export const setMessage = (message: Message) => {
  return {
    type: actionTypes.SET_MESSAGE,
    message: message
  }
}

export const setMessages = (messages: Message[]) => {
  return {
    type: actionTypes.SET_MESSAGES,
    messages: messages
  }
}

export const deleteMessage = (id: string, token: string, callback: Function): ThunkAction<void, MessagesState, unknown, Action<string>> => {
  return dispatch => {
    const bearerToken = "Bearer " + token
    axiosInstance.defaults.headers.common[ "Authorization" ] = bearerToken

    const pathName = `/messages/${id}`
    axiosInstance.delete(pathName)
      .then(res => {
        dispatch(deleteLocalMessage(id))
        callback()        
      })
      .catch(err => console.log(err))
  }
}

export const deleteLocalMessage = (id: string) => {
  return {
    type: actionTypes.DELETE_MESSAGE,
    id: id
  }
}
