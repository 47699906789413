import React from 'react'

import Form from '../Form/Form'
import css from '../../styles/login.module.css'

const Login = () => {

  return (
    <div className={ css.Login }>
      <h1>Login</h1>      
      <Form />
    </div>
  )
}

export default Login
