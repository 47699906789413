import React from 'react'

import weepingFace from '../../../assets/icons/weepingface.png'

const WeepingFace = () => {
  return (
    <img src={weepingFace} alt="Weeping face" />
  )
}

export default WeepingFace
