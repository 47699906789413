import axiosInstance from '../axios-instance'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { AuthState } from '../../interfaces'
import * as actionTypes from './actionTypes'

interface Data {
  username: string,
  password: string
}

export const authorize = (data: Data, callback: Function ): ThunkAction<void, AuthState, unknown, Action<string>> => dispatch => {
  // ASYNC actions
  const pathName = '/auth'
  const form = new FormData()
  form.append("username", data.username)
  form.append("password", data.password)
  axiosInstance.post(pathName, form)
  .then(res => {
      const token = res.data
      dispatch(setToken(token))
      storeToken(token)
      callback()
    })
    .catch(err => {
      dispatch(setFail(err.response.data, err.response.status))
    })
}

export const setToken = (token: string) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  }
}

export const setFail = (message: string, statusCode: number) => {
  return {
    type: actionTypes.AUTH_FAIL,
    message: message,
    statusCode: statusCode
  }
}

export const startAuth = () => {
  return {
    type: actionTypes.AUTH_START
  }
}

export const fetchToken = ():ThunkAction<void, AuthState, unknown, Action<string>> => dispatch => {
  const t = window.sessionStorage.getItem('token')
  if (t) {
    dispatch(setToken(t))
  }
}

export const storeToken = (token:string) => {
  window.sessionStorage.setItem('token', token)
}

export const deleteToken = (callback: Function):ThunkAction<void, AuthState, unknown, Action<string>> => dispatch => {
  callback()
  window.sessionStorage.removeItem("token")
  dispatch(logout())
  dispatch(clearMessages())
}

export const clearMessages = () => {
  return {
    type: actionTypes.CLEAR_MESSAGES
  }
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  }
}
