import React, {useEffect} from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import {connect} from 'react-redux'

import {fetchToken} from './store/actions'
import Login from './components/Login/Login'
import Nav from './components/Nav/Nav'
import Footer from './components/Footer/Footer'
import MainViewer from './components/MainViewer/MainViewer'
import SingleView from './components/SingleView/SingleView'

import css from './styles/app.module.css'

function App(props: any) {

  useEffect(() => {
    props.onFetchToken()
  },[props])

  let nav
  let routes = (
    <Switch>
      <Route component={ Login } path='/' />
      <Route component={ Login } path='/login' />
    </Switch>
  )
  if (props.auth) {
    routes = (
      <Switch>
        <Route component={ MainViewer } path='/messages' exact />
        <Route component={ SingleView } path='/messages/:id' exact />
      </Switch>

    )
    nav = <Nav />
  }

  return (
    <div className={css.App}>
      <div className={css.MovingBackground}>
        { nav }
        { routes }
        <Footer />
      </div>
    </div>
  )
}

interface State {
  auth: {
    token: string
  }
}

const mapStateToProps = (state: State) => {
  return {
    auth: state.auth.token
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchToken: () => dispatch(fetchToken())
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(withRouter(App))
